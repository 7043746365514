.footer-container, .footer-container * {
    user-select: none; 
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
}


.details span, .details-primary span {
    user-select: text; 
    -webkit-user-select: text; 
    -moz-user-select: text; 
    -ms-user-select: text; 
}


.footer-container{
    position: relative;
    left: 0;
    bottom: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    background: linear-gradient(90deg, #CE1B1B 0%, #8B0000 100%);
    color: #F0F0F0;
    opacity: 0; 
    transform: translateY(20px); 
    transition: opacity 0.6s ease-out, transform 0.6s ease-out; 
}

.footer-container.animate {
    opacity: 1; 
    transform: translateY(0); 
}
.footer-content{
    padding: 1rem 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 7rem;
    gap: 5rem;
}
.copyright, .copyright-arabic{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 3rem;
    text-align: center;
    background-color: #850303;
}
.copyright-arabic{
    direction: rtl;
}

.logo-holder{
    margin-top: 5rem;
}
.logo-container {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 1.8rem;
    box-shadow: 5px 8px 10px 8px rgba(0, 0, 0, 0.5);
    width: 239px;
    cursor: pointer;
}
.logo-container:hover {
    transform: scale(1.03);
    transition: transform 0.5s ease;
}
.logo-container span {
    font-weight: 700;
}
.logo-container img {
    width: 180px;
    height: auto;
    margin-bottom: 5px;
}
.copyright span {
    font-size: 1.15rem;
}
.custom-shape-divider-top-1723907173 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    overflow: hidden;
    line-height: 0;
    z-index: 1;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1723907173 svg {
    position: relative;
    display: block;
    width: calc(155% + 1.3px);
    height: 120px;
}

.custom-shape-divider-top-1723907173 .shape-fill {
    fill: url(#yellowGradient); 
}

.custom-shape-divider-top-grey {
    position: absolute;
    top: -20px; 
    left: 0;
    width: 100vw;
    overflow: hidden;
    line-height: 0;
    z-index: 2;
    transform: rotate(180deg);
}

.custom-shape-divider-top-grey svg {
    position: relative;
    display: block;
    width: calc(155% + 1.3px);
    height: 120px;
}

.custom-shape-divider-top-grey .shape-fill-grey {
    fill: url(#greyGradient); 
}

.custom-shape-divider-top-white {
    position: absolute;
    top: -40px; 
    left: 0;
    width: 100vw;
    overflow: hidden;
    line-height: 0;
    z-index: 3;
    transform: rotate(180deg);
}

.custom-shape-divider-top-white svg {
    position: relative;
    display: block;
    width: calc(155% + 1.3px);
    height: 120px;
}

.custom-shape-divider-top-white .shape-fill-white {
    fill: #f0f0f0; 
}
.contactInfo{
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.upper-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    font-size: 1.15rem;
}
.bottom-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    font-size: 1.15rem;
}
.details {
    font-weight: 700;
    font-size: 0.95rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.4rem;
}
.contentHeader{
    min-height: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
}
.contentHeader > .contentText{
    font-weight: 700;
    font-size: 1.11rem;
}
.copyright-text{
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    font-size: 1.11rem;
}
.details-primary{
    font-weight: 700;
    font-size: 0.95rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
}
.details-primary > span{
    white-space: nowrap;
}
@media (max-width: 941px) {
    .footer-container{
        gap: 1rem;
    }
    .footer-content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0rem;
        padding-top: 7rem; 
    }

    .upper-section, 
    .bottom-section {
        flex-direction: column;
        align-items: center;
        gap: 1.5rem; 
        width: 100%; 
    }

    .contentHeader {
        align-items: center;
        text-align: center; 
        width: 100%;
    }

    .details, 
    .details-primary {
        justify-content: center; 
    }
    .details img, .details-primary img{
        height: 18px;
        width: 18px;
    }

    .logo-holder {
        margin-top: 3rem; 
    }
    .copyright > .copyright-text{
        font-size: 0.95rem;
    }

}
@media (max-width: 470px){
    .contentHeader > .contentText {
        font-size: 0.95rem;
        width: 100%;
    }
    .details,
    .details-primary {
        font-size: 0.85rem; 
        gap: 0.2rem; 
    }
    .copyright-text {
        font-size: 0.85rem; 
    }
}
@media (max-width: 402px){
    .copyright > .copyright-text {
        font-size: 0.7rem; 
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
@media (max-width: 340px) {
    .logo-container {
        padding-top: 0.3rem;
        padding-bottom: 0.9rem;
        width: 239px;
        cursor: pointer;
    }
    .footer-container {
        gap: 0.3rem;
    }
    
    .footer-content {
        padding-top: 7rem; 
        gap: 0.5rem;
    }
    
    .upper-section,
    .bottom-section {
        gap: 1rem; 
    }

    .logo-container {
        width: 180px; 
    }

    .logo-container img {
        width: 150px; 
    }
}
@media (max-width: 335px){
    .logo-container {
        padding-top: 0.3rem;
        padding-bottom: 0.9rem;
        width: 220px;
        cursor: pointer;
    }
    .footer-container {
        gap: 0.3rem;
    }
    
    .footer-content {
        padding-top: 7rem; 
        gap: 0rem;
    }
    .contentHeader > .contentText {
        font-size: 0.8rem;
        width: 100%;
    }
    .details,
    .details-primary {
        font-size: 0.65rem; 
        gap: 0.3rem; 
    }
    .logo-container {
        width: 180px; 
    }

    .logo-container img {
        width: 150px; 
    }
    .copyright > .copyright-text {
        font-size: 0.63rem; 
        padding-left: 0.3rem;
        padding-right: 0.3rem;
    }
    .copyright-arabic > .copyright-text{
        font-size: 0.8rem; 
    }
    .details img, .details-primary img{
        height: 16px;
        width: 16px;
    }
}