@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background-color: #f0f0f0;
    font-size: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

ul > li {
    list-style: none;
}