/* Preloader.css */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #ffffff; /* White background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    overflow: hidden;
  }
  
  .spinner {
    border: 16px solid rgba(0, 0, 0, 0.1); /* Light grey border */
    border-top: 16px solid #CE1B1B; /* Red color for spinner */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }